<template>
  <div>
    <p>{{props.label}}</p>
    <div class="input-container">
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :type="openedPass || !password ? 'text' : 'password'"
        :placeholder="placeholderText"
        :class="[placeholderClass()]"
        :style="error ? { borderColor: 'red', outlineColor: 'red' } : {}"
      />
      <button v-if="password" class="eye" @click="openedPass = !openedPass">
        <img class="eye-inner" :src="openedPass ? eyeSlash : eye" alt="eye">
      </button>
    </div>
    <p v-if="error" class="error-text">{{errorText}}</p>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import eye from '../../assets/icons/eye.svg'
import eyeSlash from '../../assets/icons/eye-slash.svg'

const props = defineProps({
  modelValue: String,
  label: {
    type: String,
    default: ''
  },
  password: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorText: {
    type: String,
    default: ''
  },
  placeholderText: {
    type: String,
    default: ''
  },
  placeholderAlign: {
    type: String,
    default: 'left'
  }
})
defineEmits(['update:modelValue'])

const openedPass = ref(false)

const placeholderClass = () => {
  return `placeholder-${props.placeholderAlign}`
}
</script>

<style scoped>
p {
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  margin-bottom: 10px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

input {
  width: 100%;
  height: 50px;
  border: 2px solid #e5e5e5;
  padding: 0 15px;
  font-size: 16px;
  margin-bottom: 15px;
}

input::placeholder {
  font-size: 14px;
  color: #9ea5a1;
}

input.placeholder-left::placeholder {
  text-align: left;
}

input.placeholder-right::placeholder {
  text-align: right;
}

.eye {
  position: absolute;
  top: 15px;
  right: 10px;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.eye-inner {
  width: 22px;
}

.error-text {
  margin-top: 5px;
  margin-bottom: 0;
  text-align: right;
  color: red;
}
</style>
